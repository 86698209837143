import React from "react";
import { graphql, Link } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import CategoryPreviewGrid from "../components/category-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { fullListLink } from "./index.module.css";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    mainTopics: allSanityMainTopic {
      nodes {
        title
        id
        displayPosition
        slug {
          current
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const mainTopicsNodes = (data || {}).mainTopics ? data.mainTopics.nodes : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <h1 hidden>Covid Wegweiser</h1>
        <p>{site.description}</p>
        {mainTopicsNodes && <CategoryPreviewGrid nodes={mainTopicsNodes} />}
        <div className={fullListLink}>
          <Link to="/initiatives">Alle Initiativen alphabetisch</Link>
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
