import React from "react";
import CategoryPreview from "./category-preview";

import * as styles from "./category-preview-grid.module.css";

const sorter = (a, b) => {
  return a.displayPosition - b.displayPosition;
};

function CategoryPreviewGrid(props) {
  const mainTopics = props.nodes.sort(sorter);

  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          mainTopics.map((node) => (
            <li key={node.id}>
              <CategoryPreview {...node} />
            </li>
          ))}
      </ul>
    </div>
  );
}

CategoryPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default CategoryPreviewGrid;
