import { Link } from "gatsby";
import React from "react";
import { escapeHTMLBreakable } from "../lib/helpers";

import * as styles from "./category-preview.module.css";

function CategoryPreview(props) {
  return (
    <Link className={styles.root} to={`/main-topic/${props.slug.current}`}>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: escapeHTMLBreakable(props.title) }}
      ></div>
    </Link>
  );
}

export default CategoryPreview;
